const initialState = {
	name: '',
	email: '',
	phone: '',
	msg: '',
	botField: '',
	isLoading: false,
	err: '',
	isSend: false,
};

export default initialState;
